import React from "react"
import {
  HeroBackgroundCard as Hero,
  FeatureAlternatingWithTestimonial as Feature,
  TestimonialImageOverlapping as Testimonial,
  FeedSimple as Feed
} from "@domenicosolazzo/site-designsystem"
import Layout from "../components/Layouts/Layout"
import { Error404, DataLinks} from "../data/index"

export default function CVPage() {
    return(
        <Layout>
            <Hero />
            <Testimonial />
            <Feed />
            <Feature />
        </Layout>
    )
}